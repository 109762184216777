import React from "react";

const FancyFeatureTewentyFour = () => {
    return (
        <div className="row">
            <div
                className="col-lg-6 d-flex mb-35"
                data-aos="fade-up"
                data-aos-duration="1200"
            >
                <div className="block-style-twentyFour">
                    <div className="d-flex align-items-start">
                        <div
                            className="icon d-flex align-items-center justify-content-center">
                            <img src="images/new-icons/7.svg" alt="10-Year Homebuilder Warranty"/>
                        </div>
                        <div className="text">
                            <h4>10-Year Homebuilder Warranty</h4>
                            <p>
                                Protection from the unexpected for the next 10 years.
                            </p>
                        </div>
                    </div>
                </div>
                {/* /.block-style-twentyFour */}
            </div>
            {/* End .col */}

            <div
                className="col-lg-6 d-flex mb-35"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
            >
                <div className="block-style-twentyFour">
                    <div className="d-flex align-items-start">
                        <div
                            className="icon d-flex align-items-center justify-content-center"
                        >
                            <img src="images/new-icons/8.svg" alt="Stable Stream of Income"/>
                        </div>
                        <div className="text">
                            <h4>Stable Stream of Income</h4>
                            <p>
                                Less unexpected maintenance with the latest building standards and state-of-the-art
                                systems.
                            </p>
                        </div>
                    </div>
                </div>
                {" "}
                {/* /.block-style-twentyFour */}
            </div>
            {/* End .col */}

            <div
                className="col-lg-6 d-flex mb-35"
                data-aos="fade-up"
                data-aos-duration="1200"
            >
                <div className="block-style-twentyFour">
                    <div className="d-flex align-items-start">
                        <div
                            className="icon d-flex align-items-center justify-content-center"
                        >
                            <img src="images/new-icons/9.svg" alt="Higher Cashflow"/>
                        </div>
                        <div className="text">
                            <h4>Higher Cashflow
                            </h4>
                            <p>
                                Pay less in insurance and income taxes (Lower risk + Larger depreciation).
                            </p>
                        </div>
                    </div>
                </div>
                {" "}
                {/* /.block-style-twentyFour */}
            </div>
            {/* End .col */}

            <div
                className="col-lg-6 d-flex mb-35"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
            >
                <div className="block-style-twentyFour">
                    <div className="d-flex align-items-start">
                        <div
                            className="icon d-flex align-items-center justify-content-center"
                        >
                            <img src="images/new-icons/10.svg" alt="Higher Rental Income"/>
                        </div>
                        <div className="text">
                            <h4>Higher Rental Income</h4>
                            <p>
                                Renters will pay a premium for a new home.
                            </p>
                        </div>
                    </div>
                </div>
                {" "}
                {/* /.block-style-twentyFour */}
            </div>
            {/* End .col */}

            <div
                className="col-lg-6 d-flex mb-35"
                data-aos="fade-up"
                data-aos-duration="1200"
            >
                <div className="block-style-twentyFour">
                    <div className="d-flex align-items-start">
                        <div
                            className="icon d-flex align-items-center justify-content-center"
                        >
                            <img src="images/new-icons/11.svg" alt="Stronger Appreciation Potential"/>
                        </div>
                        <div className="text">
                            <h4>Stronger Appreciation Potential
                            </h4>
                            <p>
                                Prices tend to go up as more homes are sold in new “phases” of the community. </p>
                        </div>
                    </div>
                </div>
                {" "}
                {/* /.block-style-twentyFour */}
            </div>
            {/* End .col */}

            <div
                className="col-lg-6 d-flex mb-35"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
            >
                <div className="block-style-twentyFour">
                    <div className="d-flex align-items-start">
                        <div
                            className="icon d-flex align-items-center justify-content-center"
                        >
                            <img src="images/new-icons/12.svg" alt="Renters Love New"/>
                        </div>
                        <div className="text">
                            <h4>Renters Love New
                            </h4>
                            <p>Rent out your property faster thanks to a modern design and lower utility bills (Energy
                                efficient).
                            </p>
                        </div>
                    </div>
                </div>
                {" "}
                {/* /.block-style-twentyFour */}
            </div>
            {/* End .col */}


            {/* End .col */}
        </div>
    );
};

export default FancyFeatureTewentyFour;
