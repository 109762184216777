import React from "react";

const FeatureContent = [
    {
        icon: "1",
        title: "Exclusive Properties",
        subTitle: `Find new-build rental properties from homebuilders in the U.S`,
        dataDealy: 100
    },
    {
        icon: "2",
        title: "Attractive Financing",
        subTitle: `Get pre-approved in hours – Applies for foreign investors too`,
    },
    {
        icon: "3",
        title: "$0 Acquisition Fee",
        subTitle: `Cut out the middleman - Purchase directly from the homebuilder`,
    },
    {
        icon: "4",
        title: "You Are in Control",
        subTitle: `Manage all service providers, documents, and tasks in one place`,
    },
    {
        icon: "5",
        title: "Visible & Transparent",
        subTitle: `Understand the performance and risk of your property at a glance`,
    },
    {
        icon: "6",
        title: "Lightning-Fast Offers",
        subTitle: `Be the owner of a new-build rental property in less than 24 hours`,
    },
];

const BlipsFeatures = ({toggleSignupModal}) => {
    return (
        <div className="row justify-content-center pt-50 md-pt-20">
            {FeatureContent.map((val, i) => (
                <a className="col-lg-4 col-md-6"
                     data-aos="fade-up"
                     data-aos-duration="1200"
                     data-aos-delay={i*100}
                   href="#"
                   onClick={ e => {e.preventDefault(); toggleSignupModal()}}
                     key={i} >
                    <div className="block-style-seventeen mt-40">
                        <div className="icon d-flex align-items-center justify-content-center">
                            <img src={`images/new-icons/${val.icon}.svg`} alt={`${val.title}`} />
                        </div>
                        <div className="static-text">
                            <h3>{val.title}</h3>
                            <p>{val.subTitle}</p>
                        </div>
                    </div>
                    {/* /.block-style-seventeen */}
                </a>
            ))}
        </div>
        //   End .row
    );
};

export default BlipsFeatures;
