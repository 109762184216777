import React, {useState} from "react";
import useDocumentTitle from "../components/useDocumentTitle";
import HeaderLandingDark from "../components/header/landing/HeaderLandingDark";
import HeroBannerSix from "../components/hero-banner/HeroBannerSix";
import Portfolio from "../components/portfolio/Portfolio";
import Testimonial from "../components/testimonial/TestimonialFive";
import FooterSix from "../components/footer/FooterSix";
import Tilt from "react-parallax-tilt";
import BlipsFeatures from "../components/features/BlipsFeature";
import Modal from "react-modal";
import HeaderPopupForm from "../components/form/HeaderPopupForm";
import FancyFeatureTewentyFour from "../components/features/FancyFeatureTewentyFour";

const Blips = () => {
    const [isOpen, setIsOpen] = useState(false);

    function toggleSignupModal() {
        setIsOpen(!isOpen);
    }

    useDocumentTitle(
        "Blips - Build a more equitable future with Fast, Secure, and Frictionless Investing"
    );

    return (
        <>
            <div className="main-page-wrapper font-montserrat dark-style lg-container p0 blips-main">
                <HeaderLandingDark toggleSignupModal={toggleSignupModal}/>
                {/* End HeaderLanding */}

                {/*=============================================
			Theme Hero Banner
		  ==============================================  */}
                <div className="hero-banner-six" id="home">
                    <div className="illustration-content">
                        <img
                            src="images/new/bg.png"
                            alt="shape"
                            className="ml-auto"
                        />
                        <img
                            src="images/new/property1.png"
                            alt="screen"
                            className="property one"
                        />
                        <img
                            src="images/new/property2.png"
                            alt="screen"
                            className="property two"
                        />
                        <img
                            src="images/new/property3.png"
                            alt="screen"
                            className="property three"
                        />
                        <img
                            src="images/shape/149.svg"
                            alt="shape"
                            className="shapes shape-five"
                        />
                        <div className="shapes oval-one"></div>
                        <div className="shapes oval-two"></div>
                        <div className="shapes oval-three"></div>
                    </div>
                    {/* End .illustration-content */}

                    <div className="container">
                        <HeroBannerSix toggleSignupModal={toggleSignupModal}/>
                    </div>
                    {/* End .container */}
                </div>
                {/* /.hero-banner-six */}

                {/*=============================================
		   Fancy Feature Twelve
	    ==============================================  */}
                <div className="fancy-feature-twelve pt-120 md-pt-50" id="about">
                    <div className="bg-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8 col-lg-10 col-md-10 m-auto">
                                    <div className="title-style-six text-center">
                                        <h2>
                                            The Ultimate Real-Estate <span>Portfolio Builder</span>
                                        </h2>
                                    </div>
                                    {/* /.title-style-six */}
                                </div>
                            </div>
                            {/* End .row */}
                            <BlipsFeatures toggleSignupModal={toggleSignupModal}/>
                        </div>
                    </div>
                </div>
                <div className="pt-170 md-pt-100" id="product">
                    <div className="container">
                        <div
                            className="row pb-180 md-pb-100"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <div className="col-xl-8 col-lg-11 col-md-10 m-auto">
                                <div className="title-style-six text-center">
                                    <h2>
                                        Let Our Technology <br/>Do The <span>Work</span> For You
                                    </h2>
                                </div>
                            </div>
                        </div>
                        {/* End title */}
                        <div className="block-style-twentyThree right-image">
                            <div className="row align-items-center">
                                <div
                                    className="col-lg-6 order-lg-last ml-auto"
                                    data-aos="fade-left"
                                    data-aos-duration="1200"
                                >
                                    <img src="images/new/rounds.svg" className="bg-image-circles"/>

                                    <div className=" ml-auto">
                                        <div className="bubble-two"></div>
                                        <div className="bubble-four"></div>

                                        <img
                                            src="images/previews/screen1.png"
                                            alt="screen1"
                                            className="jump-image"
                                        />
                                    </div>
                                    {/* /.screen-container */}
                                </div>
                                <div
                                    className="col-lg-5 order-lg-first"
                                    data-aos="fade-right"
                                    data-aos-duration="1200"
                                >
                                    <div className="text-wrapper title-style-six">
                                        <h2>Search</h2>
                                        <p>
                                            Browse move-in-ready or pre-build homes you can't find anywhere else, backed
                                            by a homebuilder's warranty, home insurance, and a rental loss
                                            coverage. </p>
                                        <a className="d-flex align-items-center learn-more" href="#"
                                           onClick={e => {
                                               e.preventDefault();
                                               toggleSignupModal()
                                           }}><span>Learn More</span><img
                                            src="images/icon/93.svg" alt="arrow"/></a>

                                    </div>
                                    {/*  /.text-wrapper */}
                                </div>
                            </div>
                        </div>
                        {/* /.block-style-twentyThree */}

                        <div className="block-style-twentyThree">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div
                                        className="mr-auto"
                                        data-aos="fade-right"
                                        data-aos-duration="1200"
                                    >
                                        <div className="bubble-one"></div>
                                        <div className="bubble-three"></div>

                                        <img
                                            src="images/previews/screen2.png"
                                            alt="screen2"
                                            className="jump-image"

                                        />
                                    </div>
                                    {/* /.screen-container */}
                                </div>
                                <div
                                    className="col-lg-5 ml-auto"
                                    data-aos="fade-left"
                                    data-aos-duration="1200"
                                >
                                    <div className="text-wrapper title-style-six">
                                        <h2>Analyze</h2>
                                        <p>Use our proprietary analytics for new construction properties, before
                                            submitting your offer, to gain complete visibility into each property's
                                            performance and risk.
                                        </p>
                                        <a className="d-flex align-items-center learn-more" href="#"
                                           onClick={e => {
                                               e.preventDefault();
                                               toggleSignupModal()
                                           }}><span>Learn More</span><img
                                            src="images/icon/93.svg" alt="arrow"/></a>
                                    </div>
                                    {/* /.text-wrapper */}
                                </div>
                            </div>
                        </div>
                        {/* /.block-style-twentyThree */}

                        <div className="block-style-twentyThree right-image">
                            <div className="row">
                                <div
                                    className="col-lg-6 order-lg-last ml-auto"
                                    data-aos="fade-left"
                                    data-aos-duration="1200"
                                >
                                    <div className="ml-auto">
                                        <div className="bubble-two"></div>
                                        <div className="bubble-three"></div>

                                        <img
                                            src="images/previews/screen3.png"
                                            alt="screen3"
                                            className="jump-image"

                                        />
                                    </div>
                                    {/* /.screen-container */}
                                </div>
                                <div
                                    className="col-lg-5 order-lg-first"
                                    data-aos="fade-right"
                                    data-aos-duration="1200"
                                >
                                    <div className="text-wrapper title-style-six">
                                        <h2>Buy</h2>
                                        <p>Submit an instant offer directly to the homebuilder and control the entire
                                            transaction in a secure online deal room where you can engage, interact, and
                                            get to the finish line with all deal-related parties.
                                        </p>
                                        <a className="d-flex align-items-center learn-more" href="#"
                                           onClick={e => {
                                               e.preventDefault();
                                               toggleSignupModal()
                                           }}><span>Learn More</span><img
                                            src="images/icon/93.svg" alt="arrow"/></a>
                                    </div>
                                    {/* /.text-wrapper */}
                                </div>
                            </div>
                        </div>
                        {/* /.block-style-twentyThree */}

                        <div className="block-style-twentyThree">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div
                                        className="mr-auto"
                                        data-aos="fade-right"
                                        data-aos-duration="1200"
                                    >
                                        <img src="images/new/rounds.svg" className="bg-image-circles"/>

                                        <div className="bubble-one"></div>
                                        <div className="bubble-two"></div>
                                        <div className="bubble-three"></div>
                                        <div className="bubble-four"></div>
                                        <img
                                            src="images/previews/screen4.png"
                                            alt="screen4"
                                            className="jump-image"

                                        />
                                    </div>
                                    {/* /.screen-container */}
                                </div>
                                <div
                                    className="col-lg-5 ml-auto"
                                    data-aos="fade-left"
                                    data-aos-duration="1200"
                                >
                                    <div className="text-wrapper title-style-six">
                                        <h2>Manage</h2>
                                        <p>Manage your entire portfolio from the palm of your hand with our online
                                            dashboard and built-in property management services.
                                        </p>
                                        <a className="d-flex align-items-center learn-more" href="#"
                                           onClick={e => {
                                               e.preventDefault();
                                               toggleSignupModal()
                                           }}><span>Learn More</span><img
                                            src="images/icon/93.svg" alt="arrow"/></a>
                                    </div>
                                    {/* /.text-wrapper */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div id="new-construction">
                    <div className="fancy-feature-twentyFour pt-140 md-pt-60">
                        <div className="container">
                            <div className="col-xl-8 col-lg-10 col-md-9 m-auto">
                                <div className="title-style-six text-center">
                                    <h2>
                                        Why <span>Wall-Street</span> Investors Choose New Construction
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="mt-100 md-mt-80">
                            <div className="container">
                                <FancyFeatureTewentyFour/>
                            </div>
                        </div>
                        {/* /.bg-wrapper */}
                    </div>
                    {/* End .fancy feature twenty four */}

                </div>
                {/* /.fancy-feature-fourteen */}

                {/*=====================================================
				Fancy Portfolio One
			===================================================== */}
                <div className="fancy-portfolio-one pt-180 md-pt-100" >
                    <div className="container">
                        <div className="title-style-six">
                            <h2>
                                Choose Your <span>Blip</span>
                            </h2>
                        </div>
                        {/*  /.title-style-six */}
                    </div>

                    <div className="portfolioSliderOne arrow-top-right pt-120 md-pt-70">
                        <Portfolio onItemClick={toggleSignupModal} images={[
                            "images/properites/prop1.png",
                            "images/properites/prop2.png",
                            "images/properites/prop3.png",
                            "images/properites/prop4.png",
                            "images/properites/prop5.png",
                        ]}/>
                    </div>
                </div>
                {/* /.fancy-portfolio-one */}

                {/*=====================================================
			Client Feedback Slider Five
			===================================================== */}
                <div
                    className=" client-feedback-slider-five mt-200 md-mt-80"
                    id="customers"
                >
                    <div className="client-feedback-slider-fiv">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-7 col-lg-10 col-md-9 m-auto">
                                    <div className="title-style-six text-center mt-25">
                                        <h2>
                                            Our <span>Customers</span> Spread the Love
                                        </h2>
                                    </div>
                                    {/*  /.title-style-six */}
                                </div>
                            </div>
                            {/* End .row */}

                            <div className="row">
                                <div className="col-xl-7 col-lg-10 m-auto">
                                    <div className="custom-icon-slick-arrow clientSliderFive mt-80 md-mt-50">
                                        <Testimonial/>
                                    </div>
                                    {/* /.clientSliderThree */}
                                    <img
                                        src="images/shape/156.svg"
                                        alt="shape"
                                        className="shapes shape-one"
                                    />
                                    <img
                                        src="images/shape/157.svg"
                                        alt="shape"
                                        className="shapes shape-two"
                                    />
                                    <img
                                        src="images/shape/158.svg"
                                        alt="shape"
                                        className="shapes shape-three"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* /.container */}
                        <div className="circle-area">
                            <img src="images/shape/155.png" alt="shape" className="main-img"/>
                        </div>
                    </div>
                </div>
                {/* /.client-feedback-slider-three */}

                {/*=====================================================
					Fancy Short Banner Seven
				===================================================== */}
                <div className="fancy-short-banner-seven mt-250 md-mt-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-9 col-lg-11 m-auto">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="title-style-six text-center">
                                        <h2>
                                            Love Our Product? <br/>Sign Up <span>Now</span> For Early Access
                                        </h2>
                                    </div>
                                    <a href="#" onClick={e => {
                                        e.preventDefault();
                                        toggleSignupModal()
                                    }}
                                       className="d-flex align-items-center ios-button ">
                                        <strong>Get Access Now!</strong>
                                    </a>
                                    {/*<div className="text-left pt-35">*/}
                                    {/*    <ReferralHeroSignup/>*/}
                                    {/*</div>*/}
                                </div>
                                {/* /.title-style-six */}
                            </div>
                            {/* End .col */}
                        </div>
                        {/* End .row */}
                        <Tilt perspective={2000}>
                            <div className="screen-meta">
                                <img
                                    src="images/previews/smartphone.png"
                                    style={{"max-height": "600px"}}
                                    alt="screen"
                                    className="m-auto"
                                />
                                {/*<img*/}
                                {/*    src="images/shape/165.svg"*/}
                                {/*    alt="screen"*/}
                                {/*    className="shapes shape-four"*/}
                                {/*/>*/}
                                {/*<img*/}
                                {/*    src="images/shape/166.png"*/}
                                {/*    alt="screen"*/}
                                {/*    className="shapes shape-five"*/}
                                {/*/>*/}
                            </div>
                        </Tilt>
                    </div>
                    {/* /.container */}

                    <img
                        src="images/shape/162.svg"
                        alt="shape"
                        className="shapes shape-one"
                    />
                    <img
                        src="images/shape/163.svg"
                        alt="shape"
                        className="shapes shape-two"
                    />
                    <img
                        src="images/shape/164.svg"
                        alt="shape"
                        className="shapes shape-three"
                    />
                </div>
                {/* /.fancy-short-banner-seven */}

                {/*=====================================================
					Footer Style Six
				===================================================== */}
                <footer className="theme-footer-six" style={{"background":"url(./images/new/new-footer.jpeg) center center/cover no-repeat"}}>
                    <div className="inner-container">
                        <div className="container">
                            <FooterSix/>
                            <p className="copyright">
                                {" "}
                                Copyright @{new Date().getFullYear()}{" "}
                                <a
                                    href="https://joinportico.us/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Blips
                                </a>{" "}
                                inc.
                            </p>
                        </div>
                    </div>
                    {/*  /.inner-container */}
                </footer>
                {/* /.theme-footer-six */}
            </div>


            <Modal
                isOpen={isOpen}
                onRequestClose={toggleSignupModal}
                contentLabel="My dialog"
                className="custom-modal  modal-contact-popup-one dark-style font-montserrat"
                overlayClassName="custom-overlay"
                closeTimeoutMS={500}
            >
                <div className="box_inner ">
                    <main className="main-body box_inner modal-content clearfix">
                        <button className="close" onClick={toggleSignupModal}>
                            <img src="images/icon/close.svg" alt="close"/>
                        </button>
                        {/* End close icon */}
                        <div className="left-side">
                            <div className="d-flex flex-column justify-content-between h-100">
                                <div className="row">
                                    <div className="col-xl-10 col-lg-8 m-auto">
                                        <blockquote>
                                            “Building and Renting Single-Family Homes Is Top-Performing Investment.”
                                        </blockquote>
                                        <a className="btn-link small bio" target="_blank" href="https://www.wsj.com/articles/building-and-renting-single-family-homes-is-top-performing-investment-11636453800">—The Wall Street Journal</a>
                                    </div>
                                </div>
                                <img
                                    src="images/new/new-signup.jpeg"
                                    alt="homes"
                                    className="illustration mt-auto"
                                />
                            </div>
                        </div>
                        {/* /.left-side */}

                        <div className="right-side">
                            <h2 className="form-title">Signup for early access</h2>
                            <HeaderPopupForm onSuccessSubmit={toggleSignupModal}/>
                        </div>
                        {/*  /.right-side */}
                    </main>
                    {/* /.main-body */}
                </div>
            </Modal>
            {/* End  Modal For Request a demo */}
        </>
    );
};

export default Blips;
