import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialThree() {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    margin: 30,
  };

  const TestimonilContent = [
    {
      desc: `As a real estate professional, it was hard to impress me, but after my first meeting with Lior, I knew I could entrust him with my first portfolio deal. He understood my need to be involved and even though he took care of everything, I always had the feeling of being in control.`,
      reviewerName: "Gal Kats",
      designation: "Asset Manager",
    },
    {
      desc: `Thank you! You made this process so simple for me. I couldn’t have done it without you and your hard work. You provided me with all the confidence I needed to start my journey in real estate. Thank you so much.`,
      reviewerName: "Or Cohen",
      designation: "Emissary",
    },
    {
      desc: `Working with Lior was an outstanding experience. He helped us with so many challenges along the way and was always super responsive. Keep up the amazing work you're doing!`,
      reviewerName: "Nitai Harari",
      designation: "Business Owner",
    },
    {
      desc: `Lior helped me purchase my first investment property. If it wasn't for him, I would've never made that step. He showed me everything I needed to know about the property’s performance and risk and guided me through each step of the way.`,
      reviewerName: "Michael Shulman",
      designation: "Software Developer",
    },
    {
      desc: `Lior helped me and my husband buy our first investment property. It was scary at the beginning but thanks to Lior, we are already in the process of buying our next investment property. Thank you for your amazing work in the past 4 years!`,
      reviewerName: "Sofia Kagan",
      designation: "Insurance Specialist",
    }
  ];

  return (
    <Slider {...settings}>
      {TestimonilContent.map((val, i) => (
        <div className="item" key={i}>
          <p>{val.desc}</p>
          <div className="name">{val.reviewerName}</div>
          <span>{val.designation}</span>
        </div>
      ))}
    </Slider>
  );
}
