import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const defaultPortfolioImages = ["images/media/img_73.png","images/media/img_73.png","images/media/img_73.png","images/media/img_73.png"];

const Portfolio = ({images,onItemClick}) => {
  if (typeof images === 'undefined' || images.length === 0){
    images = defaultPortfolioImages
  }
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    centerMode: true,
    speed: 900,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {images.map((val, i) => (
          <div className="item" key={i}>
            <a onClick={ e => {e.preventDefault(); onItemClick()}} href="#" className="img-meta">
                <img src={`${val}`} alt="media" />
            </a>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default Portfolio;