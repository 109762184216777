import React, {useState} from "react";
import ReferralHeroSignup from "../form/ReferralHeroSignup";
import FormAppoint from "../form/FormAppoint";

const HeroBannerSix = ({toggleSignupModal}) => {
    return (
        <>
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <h1
                        className="font-recoleta hero-heading"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >
                        Build a <span>Successful</span> <br/> Real-Estate Portfolio Online
                    </h1>
                    <p
                        className="hero-sub-heading"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        data-aos-delay="100"
                    >
                        Sign up now to gain exclusive access to the best-performing <span>new-build rental properties</span> in the United States.
                    </p>
                    <div >
                        <a href="#" onClick={e => {
                            e.preventDefault();
                            toggleSignupModal()
                        }}
                           className="d-flex align-items-center ios-button ">
                            <strong>Sign Up</strong>
                        </a>
                    </div>

                </div>
                {/* End .col */}
            </div>
            {/* End .row */}
        </>
    );
};

export default HeroBannerSix;
