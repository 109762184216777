import React from "react";

// All HOME PAGE ROUTES
import Blips from "../views/Blips";
import ComingSoon from "../views/ComingSoon";

// Not Found Page
import NotFound from "../views/NotFound";

// Route Specific
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path="/" component={ComingSoon} />
          <Route path="/404" component={NotFound} />
          <Route path="/blips" component={Blips} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
